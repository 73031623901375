<template>
  <div class="ny_pad">
  <div class="menu_ico">
    <top_menu></top_menu>
  </div>


      <div class="banner-position container">
        <div class="index-banner swiper-container">
<!--          <div class="">-->
<!--            <div class=""  v-for="(item, index) in lunboListe" :key="index">-->
<!--              <img src="../assets/images/qywh.jpg">-->
<!--              <img :src="item.source" />-->
<!--            </div>-->
<!--          </div>-->
          <div class="content_cont" style="padding-bottom:150px;font-size:16px;color:#1b1b1b;display:inline-block;width:90%;" v-html="lunboListe.remark">
          </div>
          <div class="banner-wave">
            <svg viewBox="0 0 120 28">

              <defs>
                <path id="wave"
                      d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"/>
              </defs>
              <use id="wave3" class="wave" xlink:href="#wave" x="0" y="-2"></use>
              <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0"></use>

            </svg>
          </div>
        </div>

      </div>



  <!--	 <div class="gywm_lay1 content_lay" style="padding-top:50px;" v-for="(item, index) in lunboListe" :key="index">-->
  <!--	   <h4 class="content_tit" style="padding:20px 0;font-size:24px;color:#1b1b1b;">{{item.title}}</h4>-->
  <!--	   <div class="content_cont" style="padding-bottom:50px;font-size:16px;color:#1b1b1b;display:inline-block;width:60%;text-align:left;" v-html="item.remark">-->
  <!--	   </div>-->
  <!--	  </div>-->
  <!-- content_lay -->
  <fo_oter></fo_oter>
  </div>
</template>
<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import 'animate.css/animate.min.css'
import "font-awesome/css/font-awesome.min.css"
import axios from 'axios'
import {GETADDATA, GETDATA} from "@/apis/api";

export default {
  components: {
    top_menu, fo_oter
  },
  data() {
    return {
      lunboListe: []
    };
  },
  mounted() {
    this.showlunbo();
  },
  methods: {
    showlunbo() {
      GETDATA({id:96}).then((res) => {
        if(res.code == 200){
          this.lunboListe = res.data;
        }
      });
    }
  }
}
</script>
<style>
.index-banner{
  text-align: center;
}

.about-section {
  position: relative;
  padding: 160px 0px 10px
}
.about-section1 {
  position: relative;
  padding: 50px 0px 50px
}
.bg-color-1 {
  background: #f0f5f9;
}

.about-section .video-inner {
  position: relative;
  display: block;
  margin-right: 70px;
}

.about-section .video-inner .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%, 0% 0%);
}

.about-section .video-inner .image-box:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.about-section .video-inner .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.about-section .video-inner .image-box img {
  width: 100%;
}

.about-section .video-inner .video-btn {
  position: absolute;
  left: 35px;
  bottom: -10px;
  width: 175px;
  height: 175px;
  text-align: center;
  background: #f0f5f9;
  padding: 10px;
  border-radius: 50%;
}

.about-section .video-inner .video-btn a {
  position: relative;
  display: inline-block;
  width: 155px;
  height: 155px;
  line-height: 155px;
  text-align: center;
  border-radius: 50%;
  font-size: 36px;
  color: #fff;
  z-index: 1;
}

.about-section .video-inner .video-btn a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  opacity: 0.85;
  z-index: -1;
}

.about-section .video-inner .video-btn:after,
.about-section .video-inner .video-btn:before {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(218, 44, 70, 0.6);
  box-shadow: 0 0 0 0 rgba(218, 44, 70, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.about-section .video-inner .video-btn:after {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

h4 {
  font-size: 20px;
  line-height: 30px;
  color: #222;
  font-weight: 700;
}

h4 a {
  color: #222;
}

#content_block_one .content-box {
  position: relative;
  display: block;
}

.about-section .content-box {
  margin-right: -15px;
}

#content_block_one .content-box .sec-title {
  margin-bottom: 27px;
}

#content_block_one .content-box .text {
  margin-bottom: 33px;
  position: relative;
}

#content_block_one .content-box .inner-box .single-item {
  position: relative;
  padding: 0px 0px 0px 85px;
  margin-bottom: 31px;
}

#content_block_one .content-box .inner-box .single-item:last-child {
  margin-bottom: 0px;
}

#content_block_one .content-box .inner-box .single-item .icon-box {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 60px;
  line-height: 60px;
}

#content_block_one .content-box .inner-box .single-item .icon-box i {
  position: relative;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#content_block_one .content-box .inner-box .single-item .icon-box .bg-box {
  position: absolute;
  content: '';
  background: #f3dfe5;
  width: 40px;
  height: 60px;
  left: 10px;
  bottom: -15px;
  transform: rotate(-45deg);
  transition: all 500ms ease;
}

#content_block_one .content-box .inner-box .single-item:hover .icon-box .bg-box {
  transform: rotate(-90deg);
}

#content_block_one .content-box .inner-box .single-item h4 a:hover {

}

#content_block_one .content-box .inner-box .single-item h4 {
  margin-bottom: 10px;
}


@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    transform: translate3d(-10px, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.item-div1 ul {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-div1 ul li {
  width: 31.5%;
  margin-bottom: 3%;
}

.item-div1 ul li img {
  display: block;
  width: 100%;
  overflow: hidden;
}

.item-div1 li h3 {
  font-size: 18px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0;
}

.item-div1 li p {
  font-size: 14px;
  color: #999;
  height: 48px;
  line-height: 24px;
  overflow: hidden;
}

.item-div1 li figcaption {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
}

.item-div2 ul {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-div2 ul li {
  width: 25%;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: -1px;
}

.item-div2 ul li:nth-child(2n+1), .item-div2 ul li:nth-child(2), .item-div2 ul li:nth-child(6) {
  border-right: none;
}

.item-div2 ul li p {
  text-align: center;
  font-size: 16px;
  padding: 15px 0;
}

.item-div2 ul li figure img {
  display: block;
  width: 160px;
  margin: 20px auto 0 auto;
}

@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightSmall {
  animation-name: fadeInRightSmall;
}

@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    transform: translate3d(0%, -10px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownSmall {
  animation-name: fadeInDownSmall;
}

@keyframes fadeOutDownSmall {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(0%, 10px, 0);
  }
}

.fadeOutDownSmall {
  animation-name: fadeOutDownSmall;
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0%, 10px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
  }
}

.scaleIn {
  animation-name: scaleIn;
}

.fadeIn {
  animation-delay: 0.3s;
  animation-timing-function: linear;
}


.scroll-animate.animated {
  visibility: hidden;
}

.font-fadeIn font {
  display: inline-block;
  min-width: 12px;
}

.scrolly {
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
}

img.classGo,
.pic.classGo {
  transition: all 1.2s ease-out;
  -webkit-transition: all 1.2s ease-out;
  -moz-transition: all 1.2s ease-out;
  -o-transition: all 1.2s ease-out;
  -ms-transition: all 1.2s ease-out;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  /*filter: blur(30px);*/
  visibility: visible !important;
}

img.classGo.go,
.pic.classGo.go {
  opacity: 1;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  filter: blur(0);
}


.index-nav li {
  float: left;
  width: calc(100% / 9);
  text-align: center;
  box-sizing: border-box;
  font-size: 17px;
  padding: 10px 5px 10px 0;
}

.index-nav li a {
  color: #141418;
  display: inline-block;
  position: relative;
}

.index-nav li a:after {
  position: absolute;
  content: "";
  bottom: -10px;
  width: 100%;
  height: 3px;
  left: 0;
  background: #3279e6;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: .42s;
  -webkit-transition: .42s;
}

.index-nav li a:hover:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}

.index-nav li.on a {
  color: #3279e6;
}

.index-nav li.on a:before {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}

.index-nav li a:hover {
  color: #3279e6;
}


.banner-position {
  position: relative;
}

.index-banner .swiper-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
}

.banner-wave {
  width: 100%;
  position: absolute;
  bottom: -6px;
  z-index: 2;
}

.banner-wave > div {
  /*background-color: var(--col-deepblue);*/
  margin: -5px 0px 0px 0px;
  padding: 0px;
  color: #fff;
  text-align: center;
}

svg {
  width: 100%;
  overflow: visible;
}

.wave {
  animation: wave 3s linear;
  animation-iteration-count: infinite;
  fill: #e50014;
}

#wave2 {
  animation-duration: 5s;
  animation-direction: reverse;
  opacity: 1
}

#wave3 {
  animation-duration: 7s;
  opacity: .4;
}

@keyframes drop {
  0% {
    transform: translateY(25px);
  }
  30% {
    transform: translateY(-10px) scale(.1);
  }
  30.001% {
    transform: translateY(25px) scale(1);
  }
  70% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(-10px) scale(.1);
  }
}

@keyframes wave {
  to {
    transform: translateX(-100%);
  }
}

@keyframes ball {
  to {
    transform: translateY(20%);
  }
}


/*about*/

.index-con {
  padding: 0 100px;
  box-sizing: border-box;
  width: 100%;
}

.index-about {
  position: relative;
  z-index: 9;
  position: relative;
  margin-top: 40px;
}

.index-about .index-about-text {
  width: 50%;
}

.index-about-text p {
  color: #666666;
  line-height: 33px;
}

.index-title h3 {
  font-size: 52px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #ed720b;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, #2575f2, #6519c5);
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, #2575f2, #6519c5);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, #2575f2, #6519c5);
}

.index-title h2 {
  color: #FFFFFF;
  text-shadow: 0px 5px 15px #ed77147d;
  font-size: 92px;
  letter-spacing: -2px;
  text-transform: uppercase;
  font-weight: bold;
}

.index-title span {
  display: block;
  width: 30px;
  height: 4px;
  background: #ed720b;
  margin: 30px 0 40px;
}

.index-more a {
  display: block;
  position: relative;
  text-transform: uppercase;
  width: 210px;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  background: #ed720b;
  text-align: center;
  color: #ffffff;
  border-radius: 25px;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, #5862ef, #2c6dee);
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, #5862ef, #2c6dee);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, #5862ef, #2c6dee);
  margin: 80px 0 20px;
}

.wave_box1 {
  position: absolute;
  bottom: -40vh;
  left: 0;
  opacity: .4;
  z-index: -1;
}

.index-about-pic {
  box-shadow: 0 22px 60px -5px rgba(44, 115, 240, 0.6);
  overflow: hidden;
  border-radius: 30px;
}

.index-about-pic a {
  display: block;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.index-about-pic img {
  display: block;
  text-align: right;
  width: 100%;
  height: 100%;
}


/*业务*/

.index-yewu {
  position: relative;
  z-index: 10;
  margin-top: 7em;
}

.index-yewu-info {
  margin-top: 2em;
}

.index-yewu-pic {
  box-shadow: 10px 30px 48px 5px rgb(252 222 195);
  overflow: hidden;
  /*border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;*/
}

.index-yewu-pic img {
  display: block;
  text-align: right;
  width: 100%;
  height: 100%;
}

.index-yewu-list {
}

.index-yewu-list li {
  position: relative;
  float: left;
  width: 31%;
  margin-left: 2.3%;
  margin-top: 1.5em;
  background: #ffffff;
  padding: 30px;
  box-sizing: border-box;
}

.index-yewu-list li em {
  display: block;
  margin-bottom: 10px;
  height: 37px;
  color: #ee7815;
  font-size: 34px;
}

.index-yewu-list li .text h2 {
  color: #ee7713;
  font-size: 20px;
  margin-bottom: 10px;
}

.index-yewu-list li .text span {
  color: #666666;
  font-size: 12px;
  text-transform: uppercase;
}

.index-yewu-list li .text p {
  color: #666666;
  font-size: 13px;
  margin-top: 20px;
  height: 90px;
  overflow: hidden;
}

.index-yewu-list li a {
  display: block;
}

.index-yewu-list li:hover {
  box-sizing: border-box;
  box-shadow: 0 0 10px #84b1ff;
  -webkit-box-shadow: 1px 1px 10px rgba(132, 177, 255, 0.5);
  box-shadow: 1px 1px 10px rgba(132, 177, 255, 0.5)
}

.bz-buttonAnimat:before,
.bz-buttonAnimat:after,
.bz-buttonAnimat .inner:before,
.bz-buttonAnimat .inner:after {
  background-color: rgb(252 222 195);
}

.bz-buttonAnimat:before,
.bz-buttonAnimat:after,
.bz-buttonAnimat .inner:before,
.bz-buttonAnimat .inner:after {
  position: absolute;
  content: "";
  display: block;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.bz-buttonAnimat.horizontal:before,
.bz-buttonAnimat.horizontal:after,
.bz-buttonAnimat.both:before,
.bz-buttonAnimat.both:after {
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0)
}

.bz-buttonAnimat.horizontal .inner:before,
.bz-buttonAnimat.horizontal .inner:after,
.bz-buttonAnimat.both .inner:before,
.bz-buttonAnimat.both .inner:after {
  top: 0;
  width: 3px;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0)
}

.bz-buttonAnimat.horizontal .inner:before,
.bz-buttonAnimat.both .inner:before {
  left: 0
}

.bz-buttonAnimat.horizontal .inner:after,
.bz-buttonAnimat.both .inner:after {
  right: 0
}

.bz-buttonAnimat.horizontal .inner:hover:before,
.bz-buttonAnimat.horizontal .inner:hover:after,
.bz-buttonAnimat.both .inner:hover:before,
.bz-buttonAnimat.both .inner:hover:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1)
}

.bz-buttonAnimat.horizontal:before,
.bz-buttonAnimat.both:before {
  top: 0
}

.bz-buttonAnimat.horizontal:after,
.bz-buttonAnimat.both:after {
  bottom: 0
}

.bz-buttonAnimat.horizontal:hover:before,
.bz-buttonAnimat.horizontal:hover:after,
.bz-buttonAnimat.both:hover:before,
.bz-buttonAnimat.both:hover:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.bz-buttonAnimat.horizontal:hover .inner:before,
.bz-buttonAnimat.horizontal:hover .inner:after,
.bz-buttonAnimat.both:hover .inner:before,
.bz-buttonAnimat.both:hover .inner:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1)
}

.bz-buttonAnimat.horizontal.clockwise .inner:before,
.bz-buttonAnimat.both.clockwise .inner:before {
  -webkit-transform-origin: top center;
  transform-origin: top center
}

.bz-buttonAnimat.horizontal.clockwise .inner:after,
.bz-buttonAnimat.both.clockwise .inner:after {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center
}

.bz-buttonAnimat.horizontal.clockwise:before,
.bz-buttonAnimat.both.clockwise:before {
  -webkit-transform-origin: right center;
  transform-origin: right center
}

.bz-buttonAnimat.horizontal.clockwise:after,
.bz-buttonAnimat.both.clockwise:after {
  -webkit-transform-origin: left center;
  transform-origin: left center
}

.bz-buttonAnimat.horizontal.clockwise:hover .inner:before,
.bz-buttonAnimat.both.clockwise:hover .inner:before {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center
}

.bz-buttonAnimat.horizontal.clockwise:hover .inner:after,
.bz-buttonAnimat.both.clockwise:hover .inner:after {
  -webkit-transform-origin: top center;
  transform-origin: top center
}

.bz-buttonAnimat.horizontal.clockwise:hover:before,
.bz-buttonAnimat.both.clockwise:hover:before {
  -webkit-transform-origin: left center;
  transform-origin: left center
}

.button {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


.index-product {
  margin-top: 10em;
}

.index-product-list {
  margin-top: 4em;
}

.index-product-list li {
  float: left;
  width: 32%;
  margin-left: 2%;
  position: relative;
}

.index-product-list li em {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.index-product-list li em img {
  display: block;
  width: 100%;
  height: 100%;
}

.index-product-list li .text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  z-index: 3;
  padding: 35px;
  box-sizing: border-box;
}

.index-product-list li .text h2 {
  font-size: 26px;
  margin-bottom: 10px;
}

.index-product-list li .text p {
  font-size: 14px;
  margin-bottom: 10px;
  margin-bottom: 20px;
}

.index-product-list li .text span {
  display: block;
  margin-bottom: 20px;
}

.index-product-list li:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}

.index-product-list li:first-of-type {
  margin-left: 0;
}

.index-product-list li:hover em img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.index-product-list li em img {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  transition: all .4s ease;
}

.position-yuan {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.position-yuan .bg {
  background: url('../assets/images/yuan.png') no-repeat right center;
  z-index: 4;
  height: 731px;
  width: 731px;
  position: absolute;
  top: -120px;
  right: 0;
  background-repeat: no-repeat;
  background-color: transparent;
}




@keyframes rise {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translateY(10px);
  }
}


@media (max-width: 1024px) {
  .rc_box {
    clear: both;
    height: auto;
    overflow: hidden;
    background: #ffffff;
    padding: 50px 0 50px 0;
  }

  .rcln_t {
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
  }
}


@media all and (max-width: 1600px) {
  .index-logo {
    width: 15%
  }

  .index-nav li {
    width: calc(100% / 9);
    font-size: 16px;
  }

  .wave_box1 {
    bottom: -50vh;
  }

  .position-yuan .bg {
    transform: scale(.7);
    right: -110px
  }
}

@media all and (max-width: 1500px) {
  .index-title h3 {
    font-size: 44px;
  }

  .index-title h2 {
    font-size: 80px;
  }
}

@media all and (max-width: 1400px) {
  .index-con {
    padding: 0 4%;
  }

  .index-title h3 {
    font-size: 38px;
  }

  .index-title h2 {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .index-title h2 {
    font-size: 32px;
  }

  .about-section .video-inner {
    margin-right: 0px;
  }
}
</style>
